@use "./../../helpers/_functions.scss" as *;

.dropback {
    @include Breakpoints(mobile) {
        background-color: #00000070;
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
        visibility: hidden;
        opacity: 0;
        transition: 0.7s linear all;
    }

    &.apper-dropback {
        visibility: visible;
        opacity: 1;
        transition: 0.7s linear all;
    }
}

.bars {
    font-size: 30px;
    padding: 10px;
    color: white;
    background-color: $BaseColor;
    position: fixed;
    border-radius: 50%;
    right: 40px;
    bottom: 30px;
    opacity: 0;

    @include Breakpoints(mobile) {
        opacity: 1;
        z-index: 10;
    }
}

.allnav {
    z-index: 10;
    width: 85px;
    height: 100%;
    background-color: $BaseColor;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    p {
        color: white;
        width: 136%;
        position: relative;
        right: 13px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        height: 50px;
        text-decoration: none;
        box-sizing: border-box;
        padding-right: 20px;
        padding-left: 20px;
        transition: 0.1s linear all;
        cursor: pointer;

        .icon {
            color: white;
            font-size: 24px;
            margin: auto;
            transition: 0.1s linear all;

            &:hover {
                svg {
                    color: $HoverColor !important;
                }

                color: $HoverColor !important;
            }
        }
    }

    .Logo-colaps {
        width: 70px;
        border-radius: 5px;
        padding: 2px;
        transition: 0.3s linear all;
    }




    .Active {
        color: $HoverColor !important;
        background-color: white !important;

        .icon {
            color: $HoverColor !important;
        }

        // width: 104% !important;
    }

    @include Breakpoints(mobile) {
        transition: 0.7s linear all;

        // transform: scale(0, 1);
        // opacity: 0;
        // transform-origin: -120px 20px;
        transform: translateX(0px);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
    }

    &.apper {
        @include Breakpoints(mobile) {
            transition: 0.7s linear all;
            // transform-origin: -120px 0px;
            // transform: scale(1, 1);
            // opacity: 1;
            transform: translateX(-400px);
            -webkit-transform: translateX(-400px);
            -moz-transform: translateX(-400px);
        }
    }

    .Logout {
        position: absolute !important;
        bottom: 50px;
        font-size: 23px;

        &:hover {
            color: $HoverColor !important;
        }
    }
}

aside {
    position: relative;

    @include Breakpoints(mobile) {
        position: fixed;
        left: 0;
        top: 0;
    }

    .Remove_hover {
        &:hover {
            background-color: transparent !important;
        }
    }
}