@use "../../helpers/_functions.scss" as *;

.home-flex {
    display: flex;
    justify-content: center;
    align-items: center;



    @include Breakpoints(mobile) {
        display: block;
    }
}