@use './helpers/functions' as *;

body {

    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: white;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $BaseColor;
        border-radius: 20px;
    }
}